<template>
  <div class="page-404">
    <b-img
      :src="`${publicPath}assets/404.svg`"
      alt="Error 404: Page not found"
    />
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  data() {
    return {
      publicPath: process.env.BASE_URL,
    };
  },
};
</script>

<style lang="scss">
.page-404 {
  img {
    max-height: 66vh;
    max-width: 100%;
  }
}
</style>
